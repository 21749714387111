import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  NavigationBar,
  Item,
  CollapseItem,
  CollapseSubItem,
  PrimaryItem,
  SubItem,
} from "./styles";
import { Collapse } from "react-collapse";

export default function Careers() {
  const [activeID, setactiveID] = useState<any>();
  const menulist = [
    {
      id: "1",
      name: "ETL Developer",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis venenatis massa eu tempus. Integer eu enim diam. In vitae vulputate metus, ac dapibus turpis",
      location: "San Ramon, CA",
      note: "Remote",
      vacancies: 1,
      experience: "1 to 5",
      industry: "",
      duration: "",
    },
    {
      id: "2",
      name: "IAM",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis venenatis massa eu tempus. Integer eu enim diam. In vitae vulputate metus, ac dapibus turpis",
      location: "Plano, TX      ",
      note: "Remote",
      vacancies: 2,
      experience: "1 to 5",
      industry: "",
      duration: "6 months remote, later onsite ",
    },
    {
      id: "3",
      name: "Java Full Stack Developer",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis venenatis massa eu tempus. Integer eu enim diam. In vitae vulputate metus, ac dapibus turpis",
      location: "USA",
      note: "Remote",
      vacancies: 2,
      experience: "",
      industry: "",
      duration: "6 months",
    },
    {
      id: "4",
      name: "Java Full Stack Engineer/Lead Engineer ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis venenatis massa eu tempus. Integer eu enim diam. In vitae vulputate metus, ac dapibus turpis",
      location:
        "Mclean- VA Dallas-TX, Chicago-IL, Riverwoods-IL,Richardson - TX",
      note: "Remote & Contract",
      vacancies: 8,
      experience: "",
      industry: "BFS",
      duration: "",
    },
    {
      id: "5",
      name: ".NET Developer [ASP .NET & SQL + Production support experience",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis venenatis massa eu tempus. Integer eu enim diam. In vitae vulputate metus, ac dapibus turpis",
      location: "Chicago, IL (East coast candidate preferred)",
      note: "Remote",
      vacancies: "",
      experience: "",
      industry: "",
      duration: "",
    },
    {
      id: "6",
      name: "Salesforce Developer",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis venenatis massa eu tempus. Integer eu enim diam. In vitae vulputate metus, ac dapibus turpis",
      location: "Iselin, NJ",
      note: "Remote (Initially)",
      vacancies: "",
      experience: "9+",
      industry: "",
      duration: "12+ Months",
    },
  ];

  const handleClick = (id: any) => {
    activeID === id ? setactiveID(null) : setactiveID(id);
  };

  return (
    <>
      <section className="ttm-row carrer-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-1" />
            <div className="col-md-8 col-sm-10">
              {/* section title */}
              <div className="section-title text-center with-desc mb-40 clearfix">
                <div className="title-header mb-60">
                  {/* title-header */}
                  <h2 className="title">Open Positions at ARVERITY</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
            <div className="col-md-2 col-sm-1" />
          </div>
          <div className="row">
            <div className="col-lg-9">
              <h4 className="mb-30">We Are Looking For:</h4>

              {/* acadion */}
              <div className="style2 res-991-mt-30">
                {menulist.map((item) => (
                  <>
                    <div
                      key={item.id}
                      className={`mb-20 ${
                        activeID === item.id ? "active" : "noactive"
                      }`}
                    >
                      <CollapseItem
                        className={"justify-content-between"}
                        onClick={() => handleClick(item.id)}
                      >
                        <PrimaryItem>{item.name}</PrimaryItem>
                        <div className="collapsearrow">
                          {activeID === item.id ? (
                            <i
                              className="fa fa-angle-up pl-1"
                              aria-hidden="true"
                            />
                          ) : (
                            <i
                              className="fa fa-angle-down pl-1"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </CollapseItem>
                      <Collapse isOpened={activeID === item.id}>
                        <CollapseSubItem>
                          <div className="toggle-content vacancy-wrapper">
                            {/* <h6>Job Description</h6>
                          <p>{item.description}</p> */}
                            <div className="d-flex mt-4">
                              <b>Location: </b>
                              <p className="ml-5">{item.location}</p>
                            </div>
                            <div className="d-flex">
                              <b>Work Type: </b>
                              <p className="ml-5">{item.note}</p>
                            </div>
                            <div className="d-flex">
                              <b>Experience: </b>
                              <p className="ml-5">{item.experience}</p>
                            </div>
                            <div className="d-flex">
                              <b>Industry: </b>
                              <p className="ml-5">{item.industry}</p>
                            </div>
                            <div className="d-flex">
                              <b>Duration: </b>
                              <p className="ml-5">{item.duration}</p>
                            </div>
                            <div className="d-flex">
                              <b>Vacancies: </b>
                              <p className="ml-5">{item.vacancies}</p>
                            </div>
                            <span>
                              Please, send your CV to <a>info@arverity.com</a>{" "}
                            </span>
                          </div>
                        </CollapseSubItem>
                      </Collapse>
                    </div>
                  </>
                ))}
              </div>
              {/* acadion end*/}
            </div>
            <div className="col-lg-3">
              <div className="res-991-mt-30">
                <h4>Why Work For Us?</h4>
                <div className="row">
                  <div className="col-md-4 col-lg-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style8 text-center">
                      <div className="featured-icon">
                        {/* featured-icon*/}
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                          <i className="flaticon flaticon-consult" />
                        </div>
                      </div>
                      {/* featured-icon */}
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="featured-title">
                          <h5>Digital Economy</h5>
                        </div>
                        <div className="featured-desc">
                          <p>
                            ARVERITY helps businesses thrive in the digital
                            economy.
                          </p>
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-darkgrey btn-inline mb-20">
                          READ MORE
                        </a>
                      </div>
                      {/* featured-content */}
                    </div>
                    {/* featured-icon-box */}
                  </div>
                  <div className="col-md-4 col-lg-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style8 text-center">
                      <div className="featured-icon">
                        {/* featured-icon*/}
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                          <i className="flaticon flaticon-marketing" />
                        </div>
                      </div>
                      {/* featured-icon */}
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="featured-title">
                          <h5>24/7 Flexible</h5>
                        </div>
                        <div className="featured-desc">
                          <p>
                            We believe that the software that runs your business
                            should be flexible
                          </p>
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-darkgrey btn-inline mb-20">
                          READ MORE
                        </a>
                      </div>
                      {/* featured-content */}
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
