import React from "react";
// import { Route, Link, Switch, Redirect } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default function StaffAugmentation() {
  const img2 = require("../../../assets/images/2.png");
  const CyberSecurityVideo = require("../../../assets/images/cyber-security/cyber-security-video.gif");
  //   const CyberSecurityTitle = require("../../../assets/images/cyber-security/cyber-security-title.png");
  const CB1 = require("../../../assets/images/cyber-security/cb1.png");
  const Img19 = require("../../../assets/images/19.svg");
  const Img20 = require("../../../assets/images/20.png");
  const Img21 = require("../../../assets/images/21.png");
  const Img22 = require("../../../assets/images/22.png");

  return (
    <>
      {/* faq-section */}
      <section className="ttm-row single-project-section clearfix pt-30">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="ttm-featured-wrapper ttm-portfolio-featured-wrapper">
                  <img className="img-fluid" src={Img19} alt="" />
                  <p className="mt-30">
                    ARVERITY LLC consultancy can also provide support in
                    managing the augmentation staff, including payroll,
                    benefits, and performance management. This allows businesses
                    to focus on their core activities, while ARVERITY LLC takes
                    care of the administrative tasks involved in managing
                    temporary staff.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-lg-12">
                <div className="ttm-portfolio-title mt-0">
                  <h4 className="ttm-pf-detailbox-title">
                    Staff Augmentation Services: How You Can Benefit
                  </h4>
                </div>
                <p>
                  {" "}
                  Staff augmentation services are a way for companies to
                  temporarily increase their workforce by hiring external
                  professionals to fill specific roles or tasks. This can be a
                  useful solution for businesses that need to meet a sudden
                  increase in demand, or for those that have short-term projects
                  or specific skills gaps within their team.
                </p>
                <p>
                  {" "}
                  Our consultancy offers staff augmentation services to help
                  businesses find the right professionals to meet their needs.
                  ARVERITY LLC has a network of experienced and qualified
                  professionals who are available to work on a temporary or
                  project-based basis. This can help businesses save time and
                  resources on the hiring process and ensure that they have the
                  right skills and expertise on hand when needed.
                </p>
                <p>
                  {" "}
                  One of the benefits of using staff augmentation services is
                  that it allows businesses to scale up or down their workforce
                  as needed, without the long-term commitment of hiring
                  permanent employees. This can be especially useful for
                  businesses that experience fluctuating demand or have seasonal
                  work.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-0 res-991-mt-30">
            <div className="col-lg-12">
              <div className="ttm-portfolio-title mt-40">
                <h4 className="ttm-pf-detailbox-title">
                  What to consider while choosing a staff augmentation service
                  provider{" "}
                </h4>
              </div>
              <p>
                {" "}
                There are a few key considerations when choosing a staff
                augmentation service provider. The first is the range of skills
                and expertise available within the provider's network. It is
                important to choose a provider that has access to a wide range
                of professionals, as this will increase the chances of finding
                the right fit for your business.
              </p>
              <p>
                {" "}
                Another important factor is the provider's process for matching
                businesses with the right professionals. It is important to
                choose a provider that takes the time to understand your
                business's specific needs and goals, and that has a thorough
                process for matching you with professionals who have the
                relevant skills and experience.
              </p>
              <p>
                {" "}
                In addition to skills and expertise, it is important to consider
                the provider's reputation and track record. Look for a provider
                that has a proven track record of successful placements and
                satisfied clients.
              </p>
              <p>
                Finally, consider the cost of the service. Staff augmentation
                can be a cost-effective solution, but it is important to
                carefully consider the fees and charges associated with the
                service to ensure that it fits within your budget.
              </p>
              <p>
                ARVERITY LLC staff augmentation services can be a useful
                solution for businesses that need to temporarily increase their
                workforce or fill specific skills gaps. ARVERITY LLC consultancy
                offers a range of services to help businesses find the right
                professionals and manage their temporary staff, allowing them to
                focus on their core activities. By considering the range of
                skills and expertise available, the provider's matching process,
                reputation and track record, and cost, you can choose a staff
                augmentation service provider that meets your business's needs.
              </p>
            </div>
          </div>

          <div className="separator">
            <div className="sep-line solid mt-30 mb-30" />
          </div>
          <div className="row ttm-pf-single-related-wrapper mt-50">
            <div className="col-lg-12">
              <div className="mb-30">
                <h3 className="ttm-pf-single-related-title">
                  Staff Augmentation
                </h3>
              </div>
            </div>
            <div className="col-md-4">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image">
                <div className="ttm-box-view-content-inner">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#">
                      {" "}
                      <img className="img-fluid" src={Img20} alt="image" />
                    </a>
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link"></div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                </div>
                <div className="featured-content featured-content-portfolio text-center box-shadow2">
                  <div className="featured-title">
                    <h5>
                      <a href="portfolio-single.html">Resource Requirements</a>
                    </h5>
                  </div>
                </div>
              </div>
              {/* featured-imagebox */}
            </div>
            <div className="col-md-4">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image">
                <div className="ttm-box-view-content-inner">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#">
                      {" "}
                      <img className="img-fluid" src={Img22} alt="image" />
                    </a>
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link"></div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                </div>
                <div className="featured-content featured-content-portfolio text-center box-shadow2">
                  <div className="featured-title">
                    <h5>
                      <a href="portfolio-single.html">Resource Offboarding</a>
                    </h5>
                  </div>
                </div>
              </div>
              {/* featured-imagebox */}
            </div>
            <div className="col-md-4">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image">
                <div className="ttm-box-view-content-inner">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#">
                      {" "}
                      <img className="img-fluid" src={Img21} alt="image" />
                    </a>
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link"></div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                </div>
                <div className="featured-content featured-content-portfolio text-center box-shadow2">
                  <div className="featured-title">
                    <h5>
                      <a href="portfolio-single.html">Resource On Boarding</a>
                    </h5>
                  </div>
                </div>
              </div>
              {/* featured-imagebox */}
            </div>
          </div>
        </div>
      </section>
      {/* faq-section end */}
    </>
  );
}
