import styled from 'styled-components';

interface Selected {
    isOpen?: boolean
}

interface CustomProps {
    isSelected?: boolean;
    isOpen?: boolean;
}

export const RunaRaceWrapper = styled.div`
    .runrace-list {
        li {
            margin: 10px 0;
            padding: 2px 4px;
        }

        li:before {
            color:white!important;
            border: solid 1px;
            border-radius: 100%;
            padding: 2px 4px;
        }
    }
`;


export const NavigationWidth = '320px';

export const MainContainer = styled.div<CustomProps>`
  width: 100%;
  height:100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: ${(props: any) => (props.isViewChange ? '0' : '1rem 1.5rem')};
  background-color: red;
  box-shadow: inset 0 0 10px #ccc;
`;


export const NavigationBar = styled.div<CustomProps>`
  height: 100%;
  background-color: white;
  padding: 0 0 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: ${(props: any) => (props.isOpen ? '70px' : '300px')};
  transition-property: width, border;
  transition-duration: 0.5s;
  z-index: 99;
  height: 100%;
  box-shadow: 0 0 10px #ccc;
  padding-top: 25px;

  /* small devices responsive */
  @media (max-width: 480px) {
    border-left: ${(props: any) =>
      props.isOpen && `10px solid ` + props.theme.primary.primaryColor};
    position: absolute;
    height: calc(100% - 3.75rem);
    width: ${(props: any) =>
      props.isOpen ? '0' : `calC(${NavigationWidth} / 1.2)`};
  }
`;

export const Item = styled.div`
  margin-bottom: 0.5rem;

  .ReactCollapse--collapse {
    transition: height 250ms;
    padding-left: 2rem;
  }

  .active {
    /* background-color: #616161; */
    border-right: solid 5px  ${(props: any) => props.theme.primary.secondaryColor};
  }

  .noactive {
    background-color: transparent;
  }
`;

/// primary react-collapse  
export const CollapseItem = styled.div<CustomProps>`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  background-color: #0000ff14;
  border-left: solid 5px #004aad;
  padding-left: 30px!important;

  a{
    text-decoration: none;
    color: black;
  }

  a:hover {
    color: ${(props: any) => props.theme.primary.secondaryColor};
  }
`;

export const CollapseSubItem = styled(CollapseItem)<CustomProps>`
  padding: 10px 48px 10px 20px;

  .is-active {
    b {
      color: ${(props: any) => props.theme.primary.secondaryColor};
    }
  }
`;

export const PrimaryItem = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 600;
  flex: 2;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    padding-right: 0.5rem;
  }
`;

export const SubItem = styled.b<CustomProps>`
  font-size: 14px;
  font-weight: 500;

  &&:hover {
    color: ${(props: any) => props.theme.primary.primaryColor};
  }
`;
