import React from "react";
// import { Route, Link, Switch, Redirect } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default function OutsourcingServices() {
  const img2 = require("../../../assets/images/2.png");
  const CyberSecurityVideo = require("../../../assets/images/cyber-security/cyber-security-video.gif");
  //   const CyberSecurityTitle = require("../../../assets/images/cyber-security/cyber-security-title.png");
  const CB1 = require("../../../assets/images/cyber-security/cb1.png");
  const Img19 = require("../../../assets/images/19.svg");
  const Img20 = require("../../../assets/images/20.png");
  const Img21 = require("../../../assets/images/21.png");
  const Img23 = require("../../../assets/images/23.svg");
  const Img24 = require("../../../assets/images/24.svg");
  const Img25 = require("../../../assets/images/25.svg");
  const Img26 = require("../../../assets/images/26.svg");
  const Img32 = require("../../../assets/images/32.png");

  return (
    <>
     

      {/* broken-section */}
      <section className="ttm-row broken-section ttm-bgcolor-grey clearfix pt-40">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-12">
              <div className="position-relative skin-border text-left">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper with-top-border mt_12">
                  <img
                    className="img-fluid"
                    src={Img32}
                    title="single-img-one"
                    alt="single-img-one"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
                {/*ttm-fid*/}
                <div className="ttm-fid inside ttm-fid-view-lefticon ttm-highlight-fid-style1">
                  <div className="ttm-fid-left">
                    <div className="ttm-fid-icon-wrapper">
                      <i className="ti ti-cup" />
                    </div>
                  </div>
                  <div className="ttm-fid-contents text-left">
                    <h4 className="ttm-fid-inner">
                      <span
                        data-appear-animation="animateDigits"
                        data-from={0}
                        data-to={300}
                        data-interval={20}
                        data-before
                        data-before-style="sup"
                        data-after="+"
                        data-after-style="sub"
                      >
                        300
                      </span>
                      <span className="ml-5">+</span>
                    </h4>
                    <h3 className="ttm-fid-title">Successful Customers</h3>
                  </div>
                </div>
                {/* ttm-fid end*/}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              {/*  */}
              <div className="ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-white spacing-1 z-1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                <div className="layer-content">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>Outsourcing Services</h5>
                      <h5 className="title">
                        When it comes to outsourcing services, businesses have a
                        lot of options to choose from. Below are some of the
                        most popular types of outsourcing services:
                      </h5>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Customer Support</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Businesses can outsource customer support services
                          such as customer service, technical support, and live
                          chat. This is a great way to ensure that customer
                          inquiries are answered quickly and efficiently.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> Accounting and Payroll</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Businesses can outsource accounting and payroll
                          services to third-party providers. This is a great way
                          to save money and resources, as well as ensure
                          accuracy and compliance.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Software Development</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Businesses can outsource software development services
                          to third-party providers. This is a great way to stay
                          up-to-date with the latest technologies, as well as
                          ensure that software is developed quickly and
                          efficiently.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Marketing and Sales</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Businesses can outsource marketing and sales services
                          to third-party providers. This is a great way to reach
                          new customers and increase sales.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                           
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* broken-section */}

       {/* faq-section */}
       <section className="ttm-row single-project-section clearfix mt-40">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ttm-featured-wrapper ttm-portfolio-featured-wrapper">
                <img className="img-fluid" src={Img23} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <div className="ttm-portfolio-title mt-0">
                  <h4 className="ttm-pf-detailbox-title">
                    Unlock Growth Potential with Our Exceptional Outsourcing
                    Services!
                  </h4>
                </div>
                <p>
                  {" "}
                  Outsourcing is a business practice in which a company
                  contracts out certain business operations and processes to
                  third-party providers in order to save money and resources.
                  Arverity LLC Outsourcing is an effective way for businesses to
                  focus on their core strengths while leaving the non-core tasks
                  to professionals.
                </p>
                <p>
                  {" "}
                  Outsourcing services can include anything from customer
                  service to accounting and payroll, software development,
                  marketing, and sales. The types of outsourcing services vary
                  depending on the needs of the business. By outsourcing certain
                  services to third-party providers, businesses can save money,
                  reduce overhead costs, and increase their efficiency.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-0 res-991-mt-30">
            <div className="col-lg-12">
              <div className="ttm-portfolio-title mt-40">
                <h4 className="ttm-pf-detailbox-title">
                  Benefits of Outsourcing by choosing Arverity LLC
                </h4>
              </div>
              <ul className="custom-list">
                <li>
                  There are several benefits to outsourcing services. First and
                  foremost, outsourcing can help businesses save money by
                  cutting back on overhead costs. This is because third-party
                  providers are usually cheaper than hiring full-time employees.
                </li>
                <li>
                  Outsourcing services also allow businesses to focus on their
                  core strengths while outsourcing non-core tasks to
                  professionals. This helps businesses become more efficient and
                  productive. Additionally, businesses can benefit from the
                  expertise of a third-party provider, which can help them
                  achieve their goals faster.
                </li>
                <li>
                  Finally, outsourcing can help businesses stay ahead of the
                  competition. By Arverity LLC outsourcing certain services,
                  businesses can stay up-to-date with the latest trends and
                  technologies, which can help them gain a competitive edge.
                </li>
              </ul>
            </div>
          </div>

          <div className="separator">
            <div className="sep-line solid mt-30 mb-30" />
          </div>
        </div>
      </section>
      {/* faq-section end */}
    </>
  );
}
