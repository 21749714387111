import React from "react";
// import { Route, Link, Switch, Redirect } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default function SupportServices() {
  const img2 = require("../../../assets/images/2.png");
  const CyberSecurityVideo = require("../../../assets/images/cyber-security/cyber-security-video.gif");
  //   const CyberSecurityTitle = require("../../../assets/images/cyber-security/cyber-security-title.png");
  const CB1 = require("../../../assets/images/cyber-security/cb1.png");
  const Img19 = require("../../../assets/images/19.svg");
  const Img20 = require("../../../assets/images/20.png");
  const Img21 = require("../../../assets/images/21.png");
  const Img23 = require("../../../assets/images/23.svg");
  const Img24 = require("../../../assets/images/24.svg");
  const Img25 = require("../../../assets/images/25.svg");
  const Img26 = require("../../../assets/images/26.svg");
  const Img27 = require("../../../assets/images/27.svg");

  const Img28 = require("../../../assets/images/28.png");
  const Img29 = require("../../../assets/images/29.png");
  const Img30 = require("../../../assets/images/30.png");
  const Img31 = require("../../../assets/images/31.png");

  return (
    <>
      {/* faq-section */}
      <section className="ttm-row single-project-section clearfix pt-30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ttm-featured-wrapper ttm-portfolio-featured-wrapper">
                {/* <img className="img-fluid" src={Img23} alt="" /> */}

                <div className="mt-40">
                  <div className="ttm-portfolio-title mt-0">
                    <h4 className="ttm-pf-detailbox-title">
                      How can our support services benefit you?
                    </h4>
                  </div>
                  <p>
                    {" "}
                    If you are looking for support in setting up or running your
                    business, our consultancy can offer a range of services to
                    help you. Arverity LLC can provide advice on all aspects of
                    setting up and running a business, from financial and legal
                    issues to marketing and HR. We can also provide ongoing
                    support to help you manage your business effectively.
                  </p>
                  <p>
                    Arverity LLC support services can benefit you in many ways.
                    We can help you save time and money by providing expert
                    advice on all aspects of setting up and running your
                    business. We can also help you to avoid making mistakes that
                    could cost you dearly in the future. In addition, we can
                    provide ongoing support to ensure that your business runs
                    smoothly and efficiently.
                  </p>
                  <p>
                    So if you are looking for help in setting up or running your
                    business, our consultancy can offer a range of support
                    services that will be of great benefit to you.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-30">
              <div className="">
                <div className="ttm-portfolio-title mt-0">
                  <h4 className="ttm-pf-detailbox-title">
                    How Our Support Services Could Be Helpful For Your Business
                  </h4>
                </div>
                <p>
                  {" "}
                  At Arverity LLC consultancy, we offer a wide range of support
                  services to help businesses grow and succeed. From marketing
                  and copywriting to financial and legal advice, we have the
                  expertise and experience to help you take your business to the
                  next level.
                </p>
                <p>
                  Whether you're just starting or you've been in business for
                  years, we can provide the guidance and support you need to
                  achieve your goals. Contact us today to learn more about our
                  services and how we can help you grow your business.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-0 res-991-mt-30">
            <div className="col-lg-12">
              <div className="ttm-portfolio-title mt-40">
                <h4 className="ttm-pf-detailbox-title">
                  What types of support services does our consultancy offer?
                </h4>
              </div>
              <p>
                {" "}
                At Arverity LLC consultancy, we offer a variety of support
                services to help our clients succeed. We provide expert advice
                and guidance on all aspects of business, from start-ups to
                established companies. We can help you with business planning,
                marketing, finance, and more.
              </p>
              <p>
                {" "}
                We also offer virtual assistant services to help you with your
                day-to-day tasks. Arverity LLC's goal is to help you grow your
                business and achieve your goals. Contact us today to learn more
                about our support services and how we can help you.
              </p>
            </div>
          </div>
        
        </div>
      </section>
      {/* faq-section end */}
    </>
  );
}
