import React from "react";
// import { Route, Link, Switch, Redirect } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default function CyberSecurity() {
  const CyberSecurityVideo = require("../../../assets/images/cyber-security/cyber-security-video.gif");
  const CyberSecurityTitle = require("../../../assets/images/cyber-security/cyber-security-title.png");
  const CB1 = require("../../../assets/images/cyber-security/cb1.png");

  const CB2 = require("../../../assets/images/cyber-security/cb-application-security.png");
  const CB3 = require("../../../assets/images/cyber-security/cb-firewall-system.png");
  const CB4 = require("../../../assets/images/cyber-security/cb-data-encryption.png");
  const CB5 = require("../../../assets/images/cyber-security/cb-scanning.png");
  const CB6 = require("../../../assets/images/cyber-security/cb-threat-monitoring.png");

  const services_data = [
    {
      id: 1,
      title: "Application Security",
      description:
        "Our cyber security services are tailored to meet the specific needs of your business. We offer a range of services, including network security, data security, application security, and cloud security. We use the latest technologies and strategies to ensure your business is protected from cyber-attacks.        ",
      src: CB2,
    },
    {
      id: 2,
      title: "Firewalls System",
      description:
        "Our network security services include firewalls, intrusion detection systems, and vulnerability scanning. Arverity LLC uses firewalls to block malicious traffic and ensure only authorized users can access your network. Intrusion detection systems are used to detect unauthorized access to your network. Vulnerability scanning is used to identify security weaknesses in your system and help you address them.        ",
      src: CB3,
    },
    {
      id: 3,
      title: "Data Encryption",
      description:
        "Our data security services include encryption, tokenization, and data masking. Arverity LLC uses encryption to protect data from unauthorized access. Tokenization is used to replace sensitive data with tokens, which are unrecognizable and unreadable by unauthorized users. Data masking is used to protect sensitive data by obscuring it with random characters.        ",
      src: CB4,
    },
    {
      id: 4,
      title: "Scanning and Vulnerability testing",
      description:
        "Our application security services include application scanning and vulnerability testing. Arverity LLC uses application scanning to identify security vulnerabilities in your applications and help you address them. Vulnerability testing is used to identify and fix security flaws in your applications before they can be exploited by malicious actors.        ",
      src: CB5,
    },
    {
      id: 5,
      title: "Threat Monitoring",
      description:
        "Our cloud security services include identity and access management, data encryption, and threat monitoring. We use identity and access management to control who has access to your cloud resources and limit their access. Data encryption is used to protect data stored in the cloud from unauthorized access. Threat monitoring is used to detect and respond to threats in real time.        ",
      src: CB6,
    },
  ];

  return (
    <>
      {/* about-section */}
      <section className="ttm-row about-top-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-12">
              <div className="position-relative">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper w100">
                  <img
                    className="img-fluid"
                    src={CB1}
                    title="single-img-one"
                    alt="single-img-one"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
               
              </div>
            </div>
            <div className="col-lg-7 col-sm-12">
              <div className="pl-70 res-991-pl-0 res-991-mt-30">
                {/* section title */}
                <div className="section-title pr-55 res-991-pr-0 clearfix">
                  <div className="title-header">
                    <h5>CONSULTANCY</h5>
                    <h2 className="title">
                      Secure Your Business with our Expert Cyber Security
                      Services!
                    </h2>
                  </div>
                  <div className="title-desc">
                    Are you looking for a reliable and expert cybersecurity
                    service provider?{" "}
                    <b className="text-success"> Look no further! </b>We offer
                    specialized cyber security services to help you protect your
                    business and ensure it remains safe from cyber attacks.
                  </div>
                </div>
                {/* section title end */}
                <div className="row no-gutters mt-40">
                  <div className="col-sm-6">
                    <div className="featured-icon-box left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon*/}
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                          <i className="ti ti-shield" />
                        </div>
                      </div>
                      {/* featured-icon end*/}
                      <div className="featured-content">
                        {/* featured-content*/}
                        <div className="featured-title">
                          {/* featured-title*/}
                          <h5 className="mb-5">Advanced Analytics</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="featured-icon-box left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon*/}
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                          <i className="ti ti-archive" />
                        </div>
                      </div>
                      {/* featured-icon end*/}
                      <div className="featured-content">
                        {/* featured-content*/}
                        <div className="featured-title">
                          {/* featured-title*/}
                          <h5 className="mb-5">Private Equity</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-sm-6">
                    <div className="featured-icon-box left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon*/}
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                          <i className="ti ti-bar-chart" />
                        </div>
                      </div>
                      {/* featured-icon end*/}
                      <div className="featured-content">
                        {/* featured-content*/}
                        <div className="featured-title">
                          {/* featured-title*/}
                          <h5 className="mb-5">Corporate Finance</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="featured-icon-box left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon*/}
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                          <i className="ti ti-briefcase" />
                        </div>
                      </div>
                      {/* featured-icon end*/}
                      <div className="featured-content">
                        {/* featured-content*/}
                        <div className="featured-title">
                          {/* featured-title*/}
                          <h5 className="mb-5">Chain Management</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-45">
                  <div className="col-md-12">
                    <div className="featured-content res-767-mt-30">
                      {/* featured-content*/}
                      <div className="featured-title">
                        {/* featured-title*/}
                        <h5 className="mb-5">
                          What is Cyber Security and Why is it Important?
                        </h5>
                      </div>
                      <div className="featured-desc pr-30">
                        {/* featured-desc*/}
                        <p>
                          Cyber security is the practice of protecting networks,
                          systems, and programs from digital attacks. Cyber
                          security is important because it helps protect
                          businesses from malicious actors and ensures the
                          safety of their data and operations.
                        </p>
                      </div>
                      <a
                        className="ttm-btn ttm-btn-size-sm ttm-btn-color-darkgrey btn-inline"
                        href="#"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-45">
                  <div className="col-md-12">
                    <div className="featured-content res-767-mt-30">
                      {/* featured-content*/}
                      <div className="featured-title">
                        {/* featured-title*/}
                        <h5 className="mb-5">
                          Our Expertise in Cyber Security
                        </h5>
                      </div>
                      <div className="featured-desc pr-30">
                        {/* featured-desc*/}
                        <p>
                          At Arverity LLC company, we understand the importance
                          of cyber security and the need to protect your
                          business from malicious actors. We provide expert
                          cyber security services to help you secure your
                          business and keep your data safe. Arverity LLC has the
                          experience and expertise to ensure your business is
                          protected at all times.
                        </p>
                        <p>
                          Our team is composed of experienced professionals with
                          years of experience in the cybersecurity industry. We
                          have worked with a wide range of clients, from small
                          businesses to large corporations. Our experts have the
                          knowledge and skills to provide comprehensive
                          cybersecurity solutions for your business.
                        </p>
                        {/* <p>
                          Arverity LLC understands the latest cyber security
                          trends and knows how to identify and respond to
                          threats quickly and effectively. With our expertise,
                          we can help you create a secure and reliable
                          cybersecurity system that is tailored to your
                          business's specific needs. Arverity LLC uses the
                          latest technologies and strategies to ensure your
                          business is protected from cyber-attacks.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-section end */}

       {/* row-title-section */}
       <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img2 row-title3-section clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-1" />
            <div className="col-md-8 col-sm-10">
              <div className="text-center">
                <div className="ttm-video-btn">
                  <a
                    className="ttm-play-btn ttm_prettyphoto"
                    href="https://youtu.be/yr1Psapupsc"
                  >
                    <span className="ttm-video-btn-play">
                      <i className="fa fa-play" />
                    </span>
                  </a>
                </div>
                <div className="row-title">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>WE MAKE A DIFFERENCE</h5>
                      <h2 className="title">
                        Experienced professionals with years of experience
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-1" />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-20">
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-bgcolor-skincolor mb-20"
                  href="#"
                >
                  view more services
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* row-title-section end */}


      {/* services-section */}
      <section className="ttm-row services2-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-lg-3 col-sm-2" />
            <div className="col-md-8 col-lg-6 col-sm-8">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h2 className="title">
                    Our Cyber Security Services by Arverity LLC
                  </h2>
                </div>
              </div>
              {/* section title end */}
            </div>
            <div className="col-md-2 col-lg-3 col-sm-2" />
          </div>
          <div className="row">
            <div
              className="mt-5 d-flex flex-wrap"
              // data-item={3}
              // data-nav="false"
              // data-dots="false"
              // data-auto="true"
            >
              {services_data.map((item) => {
                return (
                  <div className="col-12 col-sm-6">
                    <div className="featured-imagebox ttm-bgcolor-white box-shadow mb-20">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src={item.src} alt="" />
                      </div>
                      <div className="ttm-box-bottom-content">
                        {/* <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                            <i className="ti ti-shield" />
                          </div>
                        </div> */}
                        <div className="featured-title featured-title">
                          {/* <h5>
                          <a tabIndex={-1}>
                            {item.title}
                          </a>
                        </h5> */}
                        </div>
                        <div className="featured-desc">
                          <p>{item.description}</p>
                          <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-darkgrey btn-inline">
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* services-section end */}

     
      {/* pricing-plan-section */}
      <section className="ttm-row pricing-plan-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-2" />
            <div className="col-md-6 col-sm-8">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h2 className="title">Types of Cyber Attacks</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
            <div className="col-md-3 col-sm-2" />
          </div>
          <div className="row">
            <div className="col-md-4">
              {/*ttm-pricing-plan*/}
              <div className="ttm-pricing-plan box-shadow text-center clearfix">
                <div className="ttm-ptablebox-title">
                  <h3>Phishing</h3>
                </div>
              </div>
              {/*ttm-pricing-plan*/}
            </div>
            <div className="col-md-4">
              {/*ttm-pricing-plan*/}
              <div className="ttm-pricing-plan box-shadow2 text-center clearfix">
                <div className="ttm-ptablebox-title">
                  <h3>Ransomware</h3>
                </div>
              </div>
              {/*ttm-pricing-plan*/}
            </div>
            <div className="col-md-4">
              {/*ttm-pricing-plan*/}
              <div className="ttm-pricing-plan box-shadow text-center clearfix">
                <div className="ttm-ptablebox-title">
                  <h3>Malware</h3>
                </div>
              </div>
              {/*ttm-pricing-plan*/}
            </div>
          </div>
        </div>
      </section>
      {/* pricing-plan-section end */}
    </>
  );
}
