import React from "react";
import MainSlider from "./MainSlider";

export default function Home() {
  const SingleImg = require("../../assets/images/single-img-four.jpeg");
  const MeetingImg = require("../../assets/images/meeting.png");
  const cloudImg = require("../../assets/images/cloud-storage.png");

  const img2 = require("../../assets/images/2.png");
  const img11 = require("../../assets/images/11.png");
  const img12 = require("../../assets/images/12.png");
  const img13 = require("../../assets/images/13.png");
  const img14 = require("../../assets/images/14.gif");
  const img15 = require("../../assets/images/15.png");
  const img16 = require("../../assets/images/16.png");
  const img17 = require("../../assets/images/17.png");
  const img18 = require("../../assets/images/18.png");

  const img37 = require("../../assets/images/37.svg");
  const img38 = require("../../assets/images/38.svg");
  const img39 = require("../../assets/images/39.svg");
  const img40 = require("../../assets/images/40.svg");

  const services_data = [
    {
      id: 1,
      title: "Cyber security",
      description:
        "We provide enterprise cybersecurity solutions to help you thrive in the face of uncertainty. Now manage risks better with the trust of AR Verity. ",
      src: img14,
    },
    {
      id: 2,
      title: "Staff augmentation",
      description:
        "Hire temporary staff to enlarge the capacity of your organization. Outsource independent projects and meet your business objectives.",
      src: img15,
    },
    {
      id: 3,
      title: "Cloud Services",
      description:
        "Take your digital transformation journey to the next level with our managed cloud services. Improve your operational efficiency while keeping your data secure and organized",
      src: img16,
    },
    {
      id: 4,
      title: "Support Services",
      description:
        "Ensure customer satisfaction with efficient support services. We help provide assistance and advice to registered users with issues concerning their technical products.",
      src: img17,
    },
    {
      id: 5,
      title: "Outsourcing Services",
      description:
        "Keep your business running by outsourcing strategy, infrastructure, business transformation, and more.",
      src: img18,
    },
  ];

  return (
    <div>
      <MainSlider />

      <div className="site-main">
        {/* services-section */}
        <section className="ttm-row services2-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-lg-3 col-sm-2" />
              <div className="col-md-8 col-lg-6 col-sm-8">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>OUR SERVICES</h5>
                    <h2 className="title">What We Offer</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className="col-md-2 col-lg-3 col-sm-2" />
            </div>
            <div className="row">
              <div
                className="mt-5 d-flex flex-wrap"
                // data-item={3}
                // data-nav="false"
                // data-dots="false"
                // data-auto="true"
              >
                {services_data.map((item) => {
                  return (
                    <div className="col-12 col-sm-4">
                      <div className="featured-imagebox ttm-bgcolor-white box-shadow mb-20">
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={item.src} alt="" />
                        </div>
                        <div className="ttm-box-bottom-content">
                          {/* <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                              <i className="ti ti-shield" />
                            </div>
                          </div> */}
                          <div className="featured-title featured-title">
                            <h5>
                              <a href="analytics-solutions.html" tabIndex={-1}>
                                {item.title}
                              </a>
                            </h5>
                          </div>
                          <div className="featured-desc">
                            <p>{item.description}</p>
                            <a
                              className="ttm-btn ttm-btn-size-sm ttm-btn-color-darkgrey btn-inline"
                              href="analytics-solutions.html"
                            >
                              READ MORE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* services-section end */}

        {/*strategy-section*/}
        <section className="ttm-row team-work-section bg-img4 clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-md-12">
                {/* col-bg-img-three */}
                <div className="">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    {/* section title */}
                    <div className="section-title pr-60 res-991-pr-0 clearfix">
                      <div className="title-header mb-50">
                        <h5>Why Integrated IT Solutions?</h5>
                        <h2 className="title">
                          Helping Businesses Navigate Digital Complexities
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <div className="row d-flex justify-content-between">
                      <div className="col-6 col-sm-3">
                        {/*featured-icon-box*/}
                        <div className="featured-icon-box style9 mb-30">
                          <div className="featured-icon">
                            {/* featured-icon*/}
                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <img src={img37} />
                            </div>
                          </div>
                          {/* featured-icon */}
                          <div className="featured-content">
                            {/* featured-content */}
                            <div className="featured-title">
                              <h5>About</h5>
                            </div>
                            <div className="featured-desc">
                              <p>
                                ARVerity LLC is an IT services consultancy
                                headquartered in Houston, Texas.
                              </p>
                            </div>
                          </div>
                          {/* featured-content */}
                        </div>
                        {/* featured-icon-box end*/}
                      </div>
                      <div className="col-6 col-sm-3">
                        {/*featured-icon-box*/}
                        <div className="featured-icon-box style9 mb-30">
                          <div className="featured-icon">
                            {/* featured-icon*/}
                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <img src={img40} />
                            </div>
                          </div>
                          {/* featured-icon */}
                          <div className="featured-content">
                            {/* featured-content */}
                            <div className="featured-title">
                              <h5>Core offerings</h5>
                            </div>
                            <div className="featured-desc">
                              <p>
                                We are focused on offering robust strategy &amp;
                                and business transformation methodologies.
                              </p>
                            </div>
                          </div>
                          {/* featured-content */}
                        </div>
                        {/* featured-icon-box end*/}
                      </div>
                      <div className="col-6 col-sm-3">
                        {/*featured-icon-box*/}
                        <div className="featured-icon-box style9 mb-30">
                          <div className="featured-icon">
                            {/* featured-icon*/}
                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <img src={img39} />
                            </div>
                          </div>
                          {/* featured-icon */}
                          <div className="featured-content">
                            {/* featured-content */}
                            <div className="featured-title">
                              <h5>Vision</h5>
                            </div>
                            <div className="featured-desc">
                              <p>
                                We aim to help our clients bridge the gap
                                between their vision and reality with our
                                trusted services.
                              </p>
                            </div>
                          </div>
                          {/* featured-content */}
                        </div>
                        {/* featured-icon-box end*/}
                      </div>
                      <div className="col-6 col-sm-3">
                        {/*featured-icon-box*/}
                        <div className="featured-icon-box style9 mb-30">
                          <div className="featured-icon">
                            {/* featured-icon*/}
                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <img src={img38} />
                            </div>
                          </div>
                          {/* featured-icon */}
                          <div className="featured-content">
                            {/* featured-content */}
                            <div className="featured-title">
                              <h5>Our Values</h5>
                            </div>
                            <div className="featured-desc">
                              <p>
                                Integrity, accountability, customer
                                satisfaction, and innovation lie at the core of
                                all our business functions.
                              </p>
                            </div>
                          </div>
                          {/* featured-content */}
                        </div>
                        {/* featured-icon-box end*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* col-bg-img-three end*/}
              </div>
              <div className="">
                {/* col-img-img-two */}
                {/* <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-right-span spacing-4">
                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                    <div className="layer-content">
                      <div className="ttm-video-btn ttm-right-center-video-btn">
                        <a
                          className="ttm-play-btn ttm_prettyphoto"
                          href="https://youtu.be/7e90gBu4pas"
                        >
                          <span className="ttm-video-btn-play">
                            <i className="fa fa-play" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div> */}
                {/* col-img-bg-img-two end*/}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/*strategy-section end*/}

        {/* about-section */}
        <section className="ttm-row about2-section clearfix mt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="position-relative pr-15 res-991-pr-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="position-relative">
                        {/* ttm_single_image-wrapper */}
                        <div className="ttm_single_image-wrapper w100">
                          <img
                            className="img-fluid"
                            src={MeetingImg}
                            title="single-img-five"
                            alt="single-img-five"
                          />
                        </div>
                        {/* ttm_single_image-wrapper end */}
                        {/*ttm-fid*/}
                        <div className="ttm-fid inside ttm-fid-view-lefticon ttm-highlight-fid-style2">
                          <div className="ttm-fid-left">
                            <div className="ttm-fid-icon-wrapper">
                              <i className="ti ti-world" />
                            </div>
                          </div>
                          <div className="ttm-fid-contents text-left">
                            <h4 className="ttm-fid-inner">
                              <span
                                data-appear-animation="animateDigits"
                                data-from={0}
                                data-to={10}
                                data-interval={5}
                                data-before
                                data-before-style="sup"
                                data-after="+"
                                data-after-style="sub"
                              >
                                10
                              </span>
                            </h4>
                            <h3 className="ttm-fid-title">
                              Years Of Experience
                            </h3>
                          </div>
                        </div>
                        {/* ttm-fid end*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="pl-15 res-991-pl-0 res-991-mt-30">
                  {/* section title */}
                  <div className="section-title pr-60 res-991-pr-0 clearfix">
                    <div className="title-header">
                      <h5>ABOUT ARVERITY</h5>
                      <h2 className="title">
                        Value-Driven Digital Transformation Solutions to Address
                        Your Most Critical Priorities
                      </h2>
                    </div>
                    <div className="title-desc">
                      We help make your business future-ready with consultation
                      and digital transformation services designed to help you
                      gain competitive advantage. Our capabilities include
                      services such as:
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="row no-gutters mt-40 mb-27 pl-10">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            IT integration and management
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Enterprise solutions
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Custom application development
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            User experience design
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Quality assurance
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Testing design
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="separator">
                    <div className="sep-line dashed" />
                  </div>
                  <div className="row no-gutters">
                    {/* <div className="col-md-6 col-sm-6">
                      <div className="featured-icon-box style1 left-icon right-border">
                        <div>
                          <img
                            src="https://via.placeholder.com/102X84/444444.png"
                            alt="sign"
                          />
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Jhon Martin</h5>
                          </div>
                          <div className="featured-desc">
                            <p>Chairnan and founder</p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6 col-sm-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* about-section end */}

        {/* process-section */}
        <section className="ttm-row ttm-bg ttm-bgimage-yes bg-img3 process-section clearfix">
          <div className="container">
            <div className="row m-0">
              <div className="col-md-3 col-sm-1" />
              <div className="col-md-6 col-sm-10">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>HOW WE WORK</h5>
                    <h2 className="title">
                      Three-Step Work Process for Optimum Quality
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className="col-md-3 col-sm-1" />
            </div>
            {/* row */}
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="row ttm-processbox-wrapper">
                  <div className="col-lg-4">
                    <div className="ttm-processbox">
                      <div className="ttm-box-image">
                        <div className="process-num">
                          <span className="number">01</span>
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h5>Planning and Organization</h5>
                        </div>
                        <div className="ttm-box-description">
                          Understanding business needs and creating a roadmap
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="ttm-processbox mt-50 res-991-mb-50">
                      <div className="ttm-box-image">
                        <div className="process-num">
                          <span className="number">02</span>
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h5>Implementing</h5>
                        </div>
                        <div className="ttm-box-description">
                          Using best practices to build valuable solutions
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="ttm-processbox">
                      <div className="ttm-box-image">
                        <div className="process-num">
                          <span className="number">03</span>
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h5>Delivery and Support </h5>
                        </div>
                        <div className="ttm-box-description">
                          Offering support services post delivery to ensure
                          smooth functioning
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* process-section end */}
        {/* client-section */}
        <section className="ttm-row team-work-section bg-img4 clearfix">
          <div className="container">
            <div className="row m-0">
              <div className="col-md-2 col-lg-3 col-sm-1" />
              <div className="col-md-8 col-lg-6 col-sm-10">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header mb-60">
                    <h5>Ready to find out more?</h5>
                    <h2 className="title">
                      Don’t stress about your IT issues, instead focus on your
                      business and let us be your solution.
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className="col-md-2 col-lg-3 col-sm-1" />
            </div>
          </div>
        </section>
        {/* client-section end*/}

        {/* blog2-section end */}
        <section className="ttm-row blog2-section clearfix pt-40">
          <div className="container">
            <div className="row align-items-center m-0">
              <div className="col-md-4">
                {/* section title */}
                <div className="section-title clearfix">
                  <div className="title-header">
                    <h5>Latest News</h5>
                    <h2 className="title text-warning">
                      cloud services does our consultancy provide?
                    </h2>
                  </div>
                  <div className="title-desc">
                    Our consultancy provides a wide range of cloud services,
                    from Infrastructure as a Service (IaaS) to Platform as a
                    Service (PaaS), and everything in between. Arverity LLC can
                    help you with everything from moving your existing
                    infrastructure to the cloud, to building out a new
                    cloud-based application from scratch.
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 col-sm-6">
                    {/* featured-imagebox-post */}
                    <div className="featured-imagebox featured-imagebox-post box-shadow mb-10">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src={cloudImg} alt="" />
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs">
                            <i className="ti ti-pencil" />
                          </div>
                        </div>
                      </div>
                      <div className="featured-content featured-content-post">
                        <div className="post-title featured-title">
                          <h5>
                            <a href="single-blog.html">
                              Setting up and configuring cloud servers
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* featured-imagebox-post end */}
                  </div>
                  <div className="col-md-12 col-sm-6">
                    {/* featured-imagebox-post */}
                    <div className="featured-imagebox featured-imagebox-post box-shadow mb-10">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src={img11} alt="" />
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs">
                            <i className="ti ti-pencil" />
                          </div>
                        </div>
                      </div>
                      <div className="featured-content featured-content-post">
                        <div className="post-title featured-title">
                          <h5>
                            <a href="single-blog.html">
                              Installing and maintaining cloud-based
                              applications
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* featured-imagebox-post end*/}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post box-shadow mb-10">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={img12} alt="" />
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs">
                        <i className="ti ti-pencil" />
                      </div>
                    </div>
                  </div>
                  <div className="featured-content featured-content-post">
                    <div className="post-title featured-title">
                      <h5>
                        <a href="single-blog.html">
                          Developing custom cloud-based solutions
                        </a>
                      </h5>
                    </div>
                    {/* <div className="post-meta">
                      <span className="ttm-meta-line">
                        <i className="fa fa-calendar" />
                        January 19, 2018
                      </span>
                      <span className="ttm-meta-line">
                        <i className="fa fa-comment" />
                        4,comments
                      </span>
                    </div> */}
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
            </div>
          </div>
        </section>
        {/* blog2-section end */}
      </div>
    </div>
  );
}
