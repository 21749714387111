import { Route, Switch, BrowserRouter } from "react-router-dom";
import React from "react";
import ScrollIntoView from "../../components/ScrollIntoView";

// single pages
import Home from "../Home";
import AboutUs from "../Pages/AboutUs";
import Contactus from "../Pages/Contactus";
import Careers from "../Pages/Careers";

// technical tab pages
import Angular from "../Pages/WebFramework/Angular";
import Python from "../Pages/WebFramework/Python";
import CloudOps from "../Pages/CloudComputing/CloudOps";
import DevOps from "../Pages/CloudComputing/DevOps";
import CyberSecurity from "../Pages/Services/CyberSecurity";
import StaffAugmentation from "../Pages/Services/StaffAugmentation";
import CloudServices from "../Pages/Services/CloudServices";
import SupportServices from "../Pages/Services/SupportServices";
import OutsourcingServices from "../Pages/Services/OutsourcingServices";
import Java from '../Pages/WebFramework/Java';
import DotNet from '../Pages/WebFramework/DotNet';

export function Router() {
  return (
    <ScrollIntoView>
      <Switch>
        <Route exact path={"/"} component={Home} />
        {/* single pages */}
        <Route path="/home" component={Home} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={Contactus} />

        {/* technical tab pages */}      
        <Route path="/Angular" component={Angular} />

        {/* software built tab pages */}
        <Route path="/CloudOps" component={CloudOps} />
        <Route path="/Python" component={Python} />
        <Route path="/Java" component={Java} />
        <Route path="/DotNet" component={DotNet} />

        {/* services tab pages */}
        <Route path="/DevOps" component={DevOps} />
        <Route path="/CyberSecurity" component={CyberSecurity} />
        <Route path="/StaffAugmentation" component={StaffAugmentation} />
        <Route path="/CloudServices" component={CloudServices} />
        <Route path="/SupportServices" component={SupportServices} />
        <Route path="/OutsourcingServices" component={OutsourcingServices} />

        {/* expedient tab pages */}
        <Route path="/careers" component={Careers} />

      </Switch>
    </ScrollIntoView>
  );
}

export default Router;
