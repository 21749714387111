import React from "react";
// import { Route, Link, Switch, Redirect } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default function CloudServices() {
  const img2 = require("../../../assets/images/2.png");
  const CyberSecurityVideo = require("../../../assets/images/cyber-security/cyber-security-video.gif");
  //   const CyberSecurityTitle = require("../../../assets/images/cyber-security/cyber-security-title.png");
  const CB1 = require("../../../assets/images/cyber-security/cb1.png");
  const Img19 = require("../../../assets/images/19.svg");
  const Img20 = require("../../../assets/images/20.png");
  const Img21 = require("../../../assets/images/21.png");
  const Img23 = require("../../../assets/images/23.svg");
  const Img24 = require("../../../assets/images/24.svg");
  const Img25 = require("../../../assets/images/25.svg");
  const Img26 = require("../../../assets/images/26.svg");
  const Img27 = require("../../../assets/images/27.svg");

  return (
    <>
      {/* faq-section */}
      <section className="ttm-row single-project-section clearfix pt-30">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ttm-featured-wrapper ttm-portfolio-featured-wrapper">
                <img className="img-fluid" src={Img23} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <div className="ttm-portfolio-title mt-0">
                  <h4 className="ttm-pf-detailbox-title">
                    Cloud services provided by our consultancy
                  </h4>
                </div>
                <p>
                  {" "}
                  Here at Arverity LLC consultancy, we provide a variety of
                  cloud services to help businesses take advantage of the
                  opportunities offered by the cloud. We work with companies of
                  all sizes, from small businesses to large enterprises, and
                  tailor our services to each client's individual needs.
                </p>
                <p>
                  {" "}
                  Our services include cloud computing, storage, networking, and
                  security. We can also help with migration and integration, as
                  well as ongoing management and support.
                </p>
                <p>
                  Whether you're just getting started with the cloud or are
                  looking to optimize your use of it, we can help. In this blog
                  post, we'll go into more detail about our cloud services and
                  how they can benefit your business.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-0 res-991-mt-30">
            <div className="col-lg-12">
              <div className="ttm-portfolio-title mt-40">
                <h4 className="ttm-pf-detailbox-title">
                  How can our consultancy's cloud services help your business?
                </h4>
              </div>
              <p>
                {" "}
                There are many ways that our consultancy's cloud services can
                help your business. Arverity LLC cloud services can help you to
                save money on your IT infrastructure, by allowing you to use
                pay-as-you-go models that scale up or down according to your
                needs.
              </p>
              <p>
                {" "}
                Our cloud services can also help you to improve your agility and
                responsiveness, by allowing you to quickly provision new
                resources and deploy new applications.
              </p>
              <p>
                {" "}
                Additionally, our cloud services can help you to improve your
                security and compliance posture, by providing you with access to
                the latest security controls and compliance monitoring tools.
              </p>
            </div>
          </div>

          <div className="separator">
            <div className="sep-line solid mt-30 mb-30" />
          </div>
          <div className="row ttm-pf-single-related-wrapper mt-50">
            <div className="col-lg-12">
              <div className="mb-30">
                <h3 className="ttm-pf-single-related-title">
                  Cloud services does our consultancy provide?
                </h3>
                <p>
                  Our consultancy provides a wide range of cloud services, from
                  Infrastructure as a Service (IaaS) to Platform as a Service
                  (PaaS), and everything in between. Arverity LLC can help you
                  with everything from moving your existing infrastructure to
                  the cloud, to building out a new cloud-based application from
                  scratch.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image">
                <div className="ttm-box-view-content-inner">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#">
                      {" "}
                      <img className="img-fluid" src={Img25} alt="image" />
                    </a>
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link"></div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                </div>
                <div className="featured-content featured-content-portfolio text-center box-shadow2">
                  <div className="featured-title">
                    <h5>
                      <a href="portfolio-single.html">
                        Setting up and configuring cloud servers
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              {/* featured-imagebox */}
            </div>
            <div className="col-md-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image">
                <div className="ttm-box-view-content-inner">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#">
                      {" "}
                      <img className="img-fluid" src={Img26} alt="image" />
                    </a>
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link"></div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                </div>
                <div className="featured-content featured-content-portfolio text-center box-shadow2">
                  <div className="featured-title">
                    <h5>
                      <a href="portfolio-single.html">
                        Installing and maintaining cloud-based applications
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              {/* featured-imagebox */}
            </div>
            <div className="col-md-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image">
                <div className="ttm-box-view-content-inner">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#">
                      {" "}
                      <img className="img-fluid" src={Img24} alt="image" />
                    </a>
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link"></div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                </div>
                <div className="featured-content featured-content-portfolio text-center box-shadow2">
                  <div className="featured-title">
                    <h5>
                      <a href="portfolio-single.html">
                        Developing custom cloud-based solutions
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              {/* featured-imagebox */}
            </div>

            <div className="col-md-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image">
                <div className="ttm-box-view-content-inner">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#">
                      {" "}
                      <img className="img-fluid" src={Img27} alt="image" />
                    </a>
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link"></div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                </div>
                <div className="featured-content featured-content-portfolio text-center box-shadow2">
                  <div className="featured-title">
                    <h5>
                      <a href="portfolio-single.html">
                        Providing support and troubleshooting for all aspects of
                        your cloud infrastructure
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              {/* featured-imagebox */}
            </div>
          </div>
        </div>
      </section>
      {/* faq-section end */}
    </>
  );
}
