import React from "react";
import { Link } from "react-router-dom";

export default function DotNet() {
  const Img36 = require("../../../assets/images/36.png");

  return (
    <div>
      <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img2 row-title3-section clearfix banner-wrapper">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row align-items-center d-flex">
            <div className="col-md-4 col-sm-4">
              {/* ttm_single_image-wrapper */}
              <div className="ttm_single_image-wrappermt_12">
                <img
                  className="img-fluid"
                  src={Img36}
                  title="single-img-one"
                  alt="single-img-one"
                />
              </div>
              {/* ttm_single_image-wrapper end */}
            </div>
            <div className="col-md-8 col-sm-8">
              <div className="text-center">
                <div className="row-title">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>THE BEST WAY TO CODE .NET BETTER</h5>
                      <h2 className="title">
                        Begin with Clean Code & End with better innovation.
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-1" />
          </div>
        </div>
      </section>

      {/* faq-section */}
      <section className="ttm-row faq-section clearfix">
        <div className="container">
          <h3 className="title mb-45">Creating many sorts of apps</h3>

          <div className="row mt-40 ">
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-idea" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>ARVERity Manages Project Costs:</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      The best .NET consulting developers will know all the
                      variables in your project to predict the budget range. We
                      ARVERITY work hard to outline them all at the start of the
                      project to bring maximum transparency to their
                      application, .NET development, Microsoft .NET development,
                      and services. The total cost of the project will include
                      not only the development of the software, but also the
                      implementation of the software in your company.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultation" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Cross Platform Design</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Because the .NET Framework platform is completely open
                      source, you can run it on all operating systems and
                      support more than 25 programming languages, including
                      Microsoft, which gives other developers an advantage. who
                      can seamlessly adapt their knowledge from one operating
                      system to another.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Robust Code</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Because the .NET Framework platform is totally open
                      source, you'll be able to run it on all operational
                      systems and support over twenty five programming
                      languages, together with Microsoft, which supplies
                      different developers a bonus.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Object oriented Program</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      With OOP, .NET developers can reuse code elements
                      throughout the process, saving you time and money from the
                      development consultation so you can apply it to other
                      domains of the company. your company.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
          </div>
        </div>
      </section>
      {/* faq-section end */}

      {/* broken-section */}
      <section className="ttm-row broken-section ttm-bgcolor-grey clearfix pt-40">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-sm-12">
              {/*  */}
              <div className="ttm-bg ttm-col-bgcolor-yes  spacing-1 z-1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                <div className="layer-content">
                  <h3 className="mb-30">
                    .NET platform for creating many sorts of apps ARVERITY's
                    areas of competence include:
                  </h3>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>

                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> Web Development</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        ARVERITY specializes in creating fast and responsive web applications using
                         a variety of Microsoft technologies, including ASP.NET MVC Core, ASP.NET MVC,
                        ASP.NET Web Forms, ASP.NET Web Pages, and SignalR, as well as the latest
                        JavaScript Frameworks such as Angular, React, VueJS, Aurelia, Polymer,
                        Ember, RiotJS, and KnockoutJS. Our web apps are robust and performant, 
                        providing value to both our clients and their consumers. Our team is able 
                        to quickly adapt and integrate new technologies as the web evolves, 
                        and our solutions are designed to be scalable and support desktop and mobile browsers.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>API Development</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        At ARVERITY, our API architects recognize the importance of scalable APIs in creating strong applications.
                         We specialize in developing enterprise-level APIs that can support and grow with your business. Our team 
                         is skilled in developing restful API solutions that are both reliable and forward-thinking, 
                         utilizing technologies such as Web API, WebHooks, and WCF.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Mobile Application Development:</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        ARVERITY's API architects understand the vital role that scalable 
                        APIs play in building robust apps. We are experts in creating 
                        enterprise-grade APIs that fuel your business, using advanced 
                        technologies like Web API, WebHooks, and WCF to deliver RESTful 
                        solutions that are both robust and future-proof. Our team is skilled 
                        in developing APIs that can support and grow with your business, 
                        providing reliable and forward-thinking solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Desktop Application Development</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        At ARVERITY consulting, we are experts in developing software applications
                         for a variety of platforms, including desktop, online, cloud, and mobile. 
                         Our team has a diverse set of skills and expertise, including experience with 
                         Microsoft technologies, tools, and programming languages. Our team of Microsoft MVPs and 
                         former Microsoft employees has a proven track record in delivering high-quality Windows 
                         Application Development services. We proficiently utilize technologies such as Windows Forms (WinForms), 
                         Windows Presentation Foundation (WPF), and Universal Windows Apps (UWP) to create custom desktop applications 
                         that meet the specific needs of your business, whether it be a stand-alone solution or a complex networked system.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* broken-section */}
    </div>
  );
}
