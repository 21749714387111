import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, Link, Switch, Redirect } from "react-router-dom";

export default function TopBar() {
  return (
    <>
      {/* ttm-topbar-wrapper */}
      <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
        <div className="container">
          <div className="ttm-topbar-content">
            <ul className="top-contact ttm-highlight-left text-left">
              <li>
                <i className="fa fa-clock-o" />
                <strong>Open Hours:</strong> Mon - Fri 8AM - 5PM
              </li>
            </ul>
            <div className="topbar-right text-right">
              <ul className="top-contact">
                <li>
                  <i className="fa fa-envelope-o" />
                  <a href="mailto:info@arverity.com.com">info@arverity.com</a>
                </li>
                <li>
                  <i className="fa fa-phone" />
                  +1 (281) 533-7001
                </li>
              </ul>
              <div className="ttm-social-links-wrapper list-inline">
                <ul className="social-icons">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100090251823174"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/arverity7/"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/arverity/"
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ttm-topbar-wrapper end */}
    </>
  );
}
