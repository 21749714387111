import React, { useRef } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import contactBg from "../../assets/images/ttm-pagetitle-bg.jpeg";

const Contactus = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_iias9m3",
        "template_58vy6gr",
        form.current,
        "ofHzBafme4mOeuCfG"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            title: "Thank you for registering!",
            text: "We will get back soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.error?.message
              ? error?.response?.data?.error?.message
              : "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
    e.target.reset();
  };

  return (
    <div>
      {/* page-title */}
      <div
        className="ttm-page-title-row"
        style={{ backgroundImage: `url(${contactBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="index.html">
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Home
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                  <span>Contact Us</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div>
        {/* contact-form-section */}
        <section className="ttm-row contact-form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="spacing-6 clearfix">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h3 className="title">
                        We’re Happy to Discuss Your Project and Answer any
                        Question
                      </h3>
                    </div>
                  </div>
                  {/* section title end */}
                  <ul className="ttm_contact_widget_wrapper">
                    <li>
                      <h6>Address</h6>
                      <i className="ttm-textcolor-skincolor ti ti-location-pin" />
                      <span>
                        6701 HWY BLVD, <br />
                        SUITE 209
                        <br /> KATY, TEXAS, 77494.
                      </span>
                    </li>
                    <li>
                      <h6>Email</h6>
                      <i className="ttm-textcolor-skincolor ti ti-comment" />
                      <span>
                        <a href="mailto:info@arverity.com">info@arverity.com</a>
                      </span>
                    </li>
                    <li>
                      <h6>Reach us</h6>
                      <i className="ttm-textcolor-skincolor ti ti-mobile" />
                      <span>+1 (281) 533-7001</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  id="ttm-contactform"
                  className="ttm-contactform wrap-form clearfix mt-50 map-col-bg ttm-bgcolor-skincolor spacing-7"
                >
                  <form id="checkout-form" ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                        <div className="form-group">
                          <label>Subject</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter subject"
                            name="subject"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                            name="name"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                                
                            </div> */}
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter your email address"
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                        <div className="form-group">
                          <label>Mobile number</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter your mobile number"
                            name="mobile"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                              
                            </div> */}
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="pLace-order mt-30">
                          <button
                            className="view-all-accent-btn"
                            type="submit"
                            value="Submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact-form-section END*/}

        {/* map-section */}
        <div className="ttm-row map-section clearfix pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/*map-start*/}
                {/* <div className="map-wrapper">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d77318.10588528721!2d78.3843328!3d17.4391296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9524e501a2d1%3A0xeb715e9d10f6e5a5!2sFlipside%20Adventure%20Park!5e1!3m2!1sen!2sin!4v1671191224845!5m2!1sen!2sin"
                    width={"100%"}
                    height={600}
                  ></iframe>
                </div> */}
                {/*map-end*/}
              </div>
            </div>
          </div>
        </div>
        {/* map-section end */}
      </div>
    </div>
  );
};

export default Contactus;
