import React from "react";
import Router from "../../routes";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.svg";
import TopBar from "./Topbar";

const Header = () => {
  return (
    <>
      <header id="masthead" className="header ttm-header-style-classic">
        <TopBar />

        {/* ttm-header-wrap */}
        <div className="ttm-header-wrap">
          {/* ttm-stickable-header-w */}
          <div
            id="ttm-stickable-header-w"
            className="ttm-stickable-header-w clearfix"
          >
            <div id="site-header-menu" className="site-header-menu">
              <div className="site-header-menu-inner ttm-stickable-header">
                <div className="container">
                  {/* site-branding */}
                  <div className="site-branding">
                    <Link to="/">
                      <img src={Logo} />
                    </Link>
                  </div>
                  {/* site-branding end */}
                  {/*site-navigation */}
                  <div id="site-navigation" className="site-navigation">
                    {/* <div className="header-btn">
                      <a
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black"
                        href="#"
                      >
                        Talk to us
                      </a>
                    </div> */}

                    <div className="ttm-menu-toggle">
                      <input type="checkbox" id="menu-toggle-form" />
                      <label
                        htmlFor="menu-toggle-form"
                        className="ttm-menu-toggle-block"
                      >
                        <span className="toggle-block toggle-blocks-1" />
                        <span className="toggle-block toggle-blocks-2" />
                        <span className="toggle-block toggle-blocks-3" />
                      </label>
                    </div>
                    <nav id="menu" className="menu">
                      <ul className="dropdown">
                        <li className="active">
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about-us">What we do</Link>
                        </li>

                        <li>
                          <a href="#">Services</a>
                          <ul>
                            <li>
                              <Link to="/OutsourcingServices">
                                Outsourcing Services
                              </Link>
                            </li>
                            <li>
                              <Link to="/CyberSecurity">Cyber Security</Link>
                            </li>
                            <li>
                              <Link to="/StaffAugmentation">
                                Staff Augmentation
                              </Link>
                            </li>
                            <li>
                              <Link to="/CloudServices">Cloud Services</Link>
                            </li>
                            <li>
                              <Link to="/SupportServices">
                                Support Services
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a href="#">Technologies</a>
                          <ul>
                            <li>
                              <Link to="/Java">Java</Link>
                            </li>
                            <li>
                              <Link to="/DotNet">.NET</Link>
                            </li>
                            <li>
                              <Link to="/DevOps">Devops</Link>
                            </li>
                            <li>
                              <Link to="/CloudOps">CloudOps</Link>
                            </li>
                            <li>
                              <Link to="/Python">Python</Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link to="/careers">Careers</Link>
                        </li>

                        <li>
                          <Link to="/contact-us">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {/* site-navigation end*/}
                </div>
              </div>
            </div>
          </div>
          {/* ttm-stickable-header-w end*/}
        </div>
        {/*ttm-header-wrap end */}
      </header>

      <Router />
    </>
  );
};

export default Header;
