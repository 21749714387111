import React from "react";
import { Link } from "react-router-dom";

export default function Python() {
  const Img34 = require("../../../assets/images/34.png");

  return (
    <div>
      <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img2 row-title3-section clearfix banner-wrapper">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-1" />
            <div className="col-md-8 col-sm-10">
              <div className="text-center">
                <div className="row-title">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>Python</h5>
                      <h2 className="title">
                        Popular Skills, clean code in your continuous
                        integration and continuous deployment workflow
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-1" />
          </div>
        </div>
      </section>

      {/* faq-section */}
      <section className="ttm-row faq-section clearfix">
        <div className="container">
          {/* <div className="row mb-40">
            <div className="ttm_single_image-wrapper mt_12">
              <img
                className="img-fluid"
                src={Img34}
                title="single-img-one"
                alt="single-img-one"
              />
            </div>
          </div> */}
          <div className="row mt-0">
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-idea" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Python project launch consulting</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      We can help you with anything from a backend component for
                      your website to a data-heavy project with complicated
                      computations. Create your product from the ground up with
                      the help of professional Python experts.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultation" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Python application performance consulting</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      If you already have a Python application, we can help you
                      improve it. Our skilled Python consultants can improve its
                      performance by optimizing code, either alone or in
                      collaboration with your team.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Python application deployment consulting</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Are you lacking in team members? Allow our Python experts
                      to assist you in expanding and growing your workforce. To
                      speed and optimize development, we may quickly hand-pick
                      the best specialists.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Staff augmentation</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Are you short on team members? Let our Python specialists
                      help you expand and grow your workforce. We can
                      immediately hand-pick the best professionals to accelerate
                      and improve development.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
          </div>
        </div>
      </section>
      {/* faq-section end */}

      {/* broken-section */}
      <section className="ttm-row broken-section ttm-bgcolor-grey clearfix pt-40 pb-40">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-sm-12">
              {/*  */}
              <div className="ttm-bg ttm-col-bgcolor-yes  spacing-1 z-1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                <div className="layer-content">
                  <h3 className="mb-30">
                    Why is relevant an excellent option for Python consulting
                    services?
                  </h3>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>

                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> 80% employee retention rate</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Great things are created by great people. We provide
                          the most comfortable environment for our Python
                          consultants in order for them to offer the best
                          outcomes.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>9/10 client satisfaction score</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We created our Python consulting firm with clients in
                          mind. Here's why you'll receive clean code, seamless
                          communication, and procedures that are fine-tuned to
                          fit your needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Dedicated project manager</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          A project manager will organize your product
                          development and launch, guide the team, monitor
                          progress, and keep you updated on the status.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Risk handling</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Nothing will go wrong with your project (save for some
                          unforeseeable force majeure scenarios), because we
                          employ cutting-edge risk management approaches to
                          avoid any potential complications.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Agile methodology</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We prioritize flexibility, which is why we use an
                          agile strategy for our Python consulting services.
                          Scale back the project scope as well as the amount of
                          individuals working on it.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>92% of senior and middle engineers</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We only choose the most qualified experts to ensure
                          that your project's timeline and quality are in good
                          hands. As a result, you will receive precisely what
                          you anticipate within the provided time frame.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* broken-section */}
    </div>
  );
}
