import React from "react";
import { Link } from "react-router-dom";

export default function Java() {
  const Img32 = require("../../../assets/images/32.png");

  return (
    <div>
      <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img2 row-title3-section clearfix banner-wrapper">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-1" />
            <div className="col-md-8 col-sm-10">
              <div className="text-center">               
                <div className="row-title">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>JAVA and Spring boot</h5>
                      <h2 className="title">clean code for Java projects and Thousands of unique rules to find Java bugs, improvements and code smells</h2>
                    </div>
                  </div>{/* section title end */}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-1" />
          </div>         
        </div>
      </section>

      {/* faq-section */}
      <section className="ttm-row faq-section clearfix">
        <div className="container">
          <h3 className="title mb-30">
          Your one-stop Java consulting partner

          </h3>
          <div className="row mt-0">
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-idea" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Java Development</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                    Our software engineers specialize in developing Java applications and building web platforms that can be integrated with other frameworks, 
                    applications or languages.

                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultation" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Java Support and Maintenance</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                    We don't just build web and mobile apps, we launch apps, products, and platforms, and maintain and troubleshoot systems.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Staff augmentation</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                    ARVERITY functions as an organization's plugin, leveraging exceptional personnel, tools, and procedures that are seamlessly integrated into the company's operations. 
                    We scale up development teams to shorten development times and to supplement current teams with ARVERITY's best practices and expertise.

                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Legacy platform</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                    When it comes to managing or developing corporate software solutions, legacy platforms are generally a factor. 
                    We thoroughly assess each case and aid our clients in maintaining outdated systems or moving them to new platforms.

                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
          </div>
        </div>
      </section>
      {/* faq-section end */}

      {/* broken-section */}
      <section className="ttm-row broken-section ttm-bgcolor-grey clearfix pt-40">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-12">
              <div className="position-relative skin-border text-left">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper with-top-border mt_12">
                  <img
                    className="img-fluid"
                    src={Img32}
                    title="single-img-one"
                    alt="single-img-one"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
                {/*ttm-fid*/}
                <div className="ttm-fid inside ttm-fid-view-lefticon ttm-highlight-fid-style1">
                  <div className="ttm-fid-left">
                    <div className="ttm-fid-icon-wrapper">
                      <i className="ti ti-cup" />
                    </div>
                  </div>
                  <div className="ttm-fid-contents text-left">
                    <h4 className="ttm-fid-inner">
                      <span
                        data-appear-animation="animateDigits"
                        data-from={0}
                        data-to={300}
                        data-interval={20}
                        data-before
                        data-before-style="sup"
                        data-after="+"
                        data-after-style="sub"
                      >
                        300
                      </span>
                      <span className="ml-5">+</span>
                    </h4>
                    <h3 className="ttm-fid-title">Successful Customers</h3>
                  </div>
                </div>
                {/* ttm-fid end*/}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              {/*  */}
              <div className="ttm-bg ttm-col-bgcolor-yes  spacing-1 z-1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                <div className="layer-content">
                  <h3 className="mb-30">Java services</h3>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>

                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>What is the best tool for Java development?</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        The best tools according to our Java consultants are: IntelliJ, Maven, Spring Framework. 
                        These tools have everything you need to build an integrated development. 
                        They offer a different set of tools for the most used frameworks and have a lot of great support plugins available.

                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>In what areas do ARVERITY have experience creating Java applications?</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        As a Java development company, our experts have worked with various clients and projects creating Java applications. 
                        Over the years we have accumulated Java development experience in various industries working with streaming services, crypto trading platforms and banking and BUS transactions.

                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Does ARVERITY offer post-development support?</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        Right. An important part of being a Java consultant means that once the application is created or the development is ready, the work continues. 
                        We monitor to ensure that everything is not only working, but also achieving our goals and succeeding in user acquisition. 
                        At ARVERITY, we believe that software development is a continuous process with no end, it improves, refines and becomes more efficient.

                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Consider ARVERITY as your one-stop Java consulting partner</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                        ARVERITY has experience in providing premium Java consulting services. 
                        We help global customers streamline their business processes by developing best-in-class web, mobile and enterprise applications. 
                        In addition, we have deep expertise in developing next-generation software applications that can increase operational productivity and improve overall business performance.

                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* broken-section */}
    </div>
  );
}
