import React, { FunctionComponent } from "react";
// import Router from "../../routes";
// import { Route, Link, Switch, Redirect } from "react-router-dom";
import Logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";

const Footer: FunctionComponent = () => {
  return (
    <>
      <footer className="footer widget-footer clearfix">
        <div className="first-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="first-footer-inner">
                  <div className="footer-logo">
                    <img
                      id="footer-logo-img"
                      className="img-center"
                      src={Logo}
                      alt=""
                    />
                  </div>
                  <div className="row no-gutters footer-box">
                    <div className="col-md-4 widget-area">
                      <div className="featured-box text-center">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Our Address</h5>
                          </div>
                          <div className="featured-desc">
                            <p>
                              {" "}
                              6701 HWY BLVD, <br />
                              SUITE 209 <br />
                              KATY, TEXAS, 77494.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 widget-area">
                      <div className="featured-box text-center">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Talk To Expert</h5>
                          </div>
                          <div className="featured-desc">
                            <p>+1 (281) 533-7001</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 widget-area">
                      <div className="featured-box text-center">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Email Us</h5>
                          </div>
                          <div className="featured-desc">
                            <p>info@arverity.com</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-3 col-sm-2" />
                    <div className="col-md-6 col-sm-8">
                      <div>
                        <form
                          id="subscribe-form"
                          className="newsletter-form"
                          method="post"
                          action="#"
                          data-mailchimp="true"
                        >
                          <div
                            className="mailchimp-inputbox clearfix"
                            id="subscribe-content"
                          >
                            <i className="fa fa-envelope-o" />
                            <input
                              type="email"
                              name="email"
                              placeholder="Your Email Address.."
                              required
                            />
                            <input type="submit" defaultValue="SUBMIT" />
                          </div>
                          <div id="subscribe-msg" />
                        </form>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-footer ttm-textcolor-white">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_text  clearfix">
                  <h3 className="widget-title">About ARVERITY</h3>
                  <div className="textwidget widget-text">
                    We help make your business future-ready with consultation
                    and digital transformation services designed to help you
                    gain competitive advantage. Our capabilities include
                    services such as:
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_nav_menu clearfix">
                  <h3 className="widget-title">Quick Links</h3>
                  <ul id="menu-footer-services">
                    <li>
                      <Link to="/CyberSecurity">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/StaffAugmentation">Staff Augmentation</Link>
                    </li>
                    <li>
                      <Link to="/">Cloud Services</Link>
                    </li>
                    <li>
                      <Link to="/">Support Services</Link>
                    </li>
                    <li>
                      <Link to="/">Outsourcing Services</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_nav_menu clearfix">
                  <h3 className="widget-title">Technologies</h3>
                  <ul id="menu-footer-services">
                    <li>
                      <Link to="/">Java</Link>
                    </li>
                    <li>
                      <Link to="/">.NET</Link>
                    </li>
                    <li>
                      <Link to="/">Devops</Link>
                    </li>
                    <li>
                      <Link to="/">CloudOps</Link>
                    </li>
                    <li>
                      <Link to="/">Angular / React</Link>
                    </li>
                    <li>
                      <Link to="/">Python</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget flicker_widget clearfix mb-0">
                  <h3 className="widget-title">Address</h3>
                  <div className="textwidget widget-text">
                    <ul className="ttm-our-location-list">
                      <li>
                        <b>Address:</b>
                      </li>
                      <li>
                        6701 HWY BLVD, <br />
                        SUITE 209
                        <br /> KATY, TEXAS, 77494.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="widget flicker_widget clearfix mt-10">
                  <h3 className="widget-title">Social media</h3>
                  <div className="textwidget widget-text">
                    <ul className="ttm-our-location-list">
                      <li>
                        <div className="social-icons circle social-hover">
                          <ul className="list-inline">
                            <li className="social-facebook">
                              <a
                                className="tooltip-top"
                                target="_blank"
                                href="https://www.facebook.com/profile.php?id=100090251823174"
                                data-tooltip="Facebook"
                              >
                                <i
                                  className="fa fa-facebook"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li className="social-instagram">
                              <a
                                className="tooltip-top"
                                target="_blank"
                                href="https://www.instagram.com/arverity7/"
                                data-tooltip="Instagram"
                              >
                                <i
                                  className="fa fa-instagram"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li className="social-linkedin">
                              <a
                                className=" tooltip-top"
                                target="_blank"
                                href="https://www.linkedin.com/company/arverity/"
                                data-tooltip="LinkedIn"
                              >
                                <i
                                  className="fa fa-linkedin"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-textcolor-white">
          <div className="container">
            <div className="row copyright">
              <div className="col-md-12">
                <div>
                  <span>
                    Copyright © 2022&nbsp;<a href="#">NEWDEVEX</a>. All rights
                    reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
