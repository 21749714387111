import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  const SingleImg = require("../../assets/images/single-img-four.jpeg");
  const MeetingImg = require("../../assets/images/meeting.png");
  const cloudImg = require("../../assets/images/cloud-storage.png");
  const img2 = require("../../assets/images/2.png");
  const img11 = require("../../assets/images/11.png");
  const img12 = require("../../assets/images/12.png");
  const img13 = require("../../assets/images/13.png");
  const img14 = require("../../assets/images/14.gif");
  const img15 = require("../../assets/images/15.png");
  const img16 = require("../../assets/images/16.png");
  const img17 = require("../../assets/images/17.png");
  const img18 = require("../../assets/images/18.png");

  return (
    <div>
      {/* blog2-section end */}
      <section className="ttm-row broken-section ttm-bgcolor-grey clearfix pt-40 pb-40">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* section title */}
              <div className="section-title clearfix">
                <div className="title-header">
                  <h5>Latest News</h5>
                  <h2 className="title text-danger">
                    cloud services does our consultancy provide?
                  </h2>
                </div>
                <div className="title-desc">
                  Our consultancy provides a wide range of cloud services, from
                  Infrastructure as a Service (IaaS) to Platform as a Service
                  (PaaS), and everything in between. Arverity LLC can help you
                  with everything from moving your existing infrastructure to
                  the cloud, to building out a new cloud-based application from
                  scratch.
                </div>
              </div>
              {/* section title end */}
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 col-sm-6">
                  {/* featured-imagebox-post */}
                  <div className="featured-imagebox featured-imagebox-post box-shadow mb-10">
                    <div className="featured-thumbnail">
                      <img className="img-fluid" src={cloudImg} alt="" />
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs">
                          <i className="ti ti-pencil" />
                        </div>
                      </div>
                    </div>
                    <div className="featured-content featured-content-post">
                      <div className="post-title featured-title">
                        <h5>
                          <a href="single-blog.html">
                            Setting up and configuring cloud servers
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox-post end */}
                </div>
                <div className="col-md-12 col-sm-6">
                  {/* featured-imagebox-post */}
                  <div className="featured-imagebox featured-imagebox-post box-shadow mb-10">
                    <div className="featured-thumbnail">
                      <img className="img-fluid" src={img11} alt="" />
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs">
                          <i className="ti ti-pencil" />
                        </div>
                      </div>
                    </div>
                    <div className="featured-content featured-content-post">
                      <div className="post-title featured-title">
                        <h5>
                          <a href="single-blog.html">
                            Installing and maintaining cloud-based applications
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox-post end*/}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {/* featured-imagebox-post */}
              <div className="featured-imagebox featured-imagebox-post box-shadow mb-10">
                <div className="featured-thumbnail">
                  <img className="img-fluid" src={img12} alt="" />
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs">
                      <i className="ti ti-pencil" />
                    </div>
                  </div>
                </div>
                <div className="featured-content featured-content-post">
                  <div className="post-title featured-title">
                    <h5>
                      <a href="single-blog.html">
                        Developing custom cloud-based solutions
                      </a>
                    </h5>
                  </div>
                  {/* <div className="post-meta">
                      <span className="ttm-meta-line">
                        <i className="fa fa-calendar" />
                        January 19, 2018
                      </span>
                      <span className="ttm-meta-line">
                        <i className="fa fa-comment" />
                        4,comments
                      </span>
                    </div> */}
                </div>
              </div>
              {/* featured-imagebox-post end */}
            </div>
          </div>
        </div>
      </section>
      {/* blog2-section end */}

      {/* about-section */}
      <section className="ttm-row about2-section clearfix pt-100 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="position-relative pr-15 res-991-pr-0">
                <div className="row mb-30">
                  <div className="col-md-7">
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper ttm-991-center">
                      <img
                        className="img-fluid"
                        src={img13}
                        title="single-img-three"
                        alt="single-img-three"
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                  <div className="col-md-5">
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper ttm-991-center res-767-mt-30">
                      <img
                        className="img-fluid"
                        src={SingleImg}
                        title="single-img-four"
                        alt="single-img-four"
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="position-relative">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper w100">
                        <img
                          className="img-fluid"
                          src={MeetingImg}
                          title="single-img-five"
                          alt="single-img-five"
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                      {/*ttm-fid*/}
                      <div className="ttm-fid inside ttm-fid-view-lefticon ttm-highlight-fid-style2">
                        <div className="ttm-fid-left">
                          <div className="ttm-fid-icon-wrapper">
                            <i className="ti ti-world" />
                          </div>
                        </div>
                        <div className="ttm-fid-contents text-left">
                          <h4 className="ttm-fid-inner">
                            <span
                              data-appear-animation="animateDigits"
                              data-from={0}
                              data-to={10}
                              data-interval={5}
                              data-before
                              data-before-style="sup"
                              data-after="+"
                              data-after-style="sub"
                            >
                              10
                            </span>
                          </h4>
                          <h3 className="ttm-fid-title">Years Of Experience</h3>
                        </div>
                      </div>
                      {/* ttm-fid end*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="pl-15 res-991-pl-0 res-991-mt-30">
                {/* section title */}
                <div className="section-title pr-60 res-991-pr-0 clearfix">
                  <div className="title-header">
                    <h5>ABOUT ARVERITY</h5>
                    <h2 className="title">
                      Value-Driven Digital Transformation Solutions to Address
                      Your Most Critical Priorities
                    </h2>
                  </div>
                  <div className="title-desc">
                    We help make your business future-ready with consultation
                    and digital transformation services designed to help you
                    gain competitive advantage. Our capabilities include
                    services such as:
                  </div>
                </div>
                {/* section title end */}
                <div className="row no-gutters mt-40 mb-27">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          IT integration and management
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Enterprise solutions
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Custom application development
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          User experience design
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Quality assurance
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Testing design
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line dashed" />
                </div>
                <div className="row no-gutters">
                  {/* <div className="col-md-6 col-sm-6">
                      <div className="featured-icon-box style1 left-icon right-border">
                        <div>
                          <img
                            src="https://via.placeholder.com/102X84/444444.png"
                            alt="sign"
                          />
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Jhon Martin</h5>
                          </div>
                          <div className="featured-desc">
                            <p>Chairnan and founder</p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  <div className="col-md-6 col-sm-6">
                    {/*featured-icon-box*/}
                    <div className="featured-icon-box style1 without-icon right-border">
                      <div className="featured-content">
                        <div className="featured-title">
                          <h5>+1 (281) 533-7001</h5>
                        </div>
                        <div className="featured-desc">
                          <p>Call to ask any question</p>
                        </div>
                      </div>
                    </div>
                    {/* featured-icon-box end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-section end */}

      {/* services-section */}
      <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img1 about-services-section clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2 col-sm-1" />
            <div className="col-lg-6 col-md-8 col-sm-10">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header mb-60">
                  <h5>WHO WE ARE</h5>
                  <h2 className="title">
                    Helping Businesses Navigate Digital Complexities
                  </h2>
                </div>
              </div>
              {/* section title end */}
            </div>
            <div className="col-lg-3 col-md-2 col-sm-1" />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center ptb-50 res-991-ptb-20 iconalign-before-heading-row">
                <div className="col-md-4">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box iconalign-before-heading style1">
                    <div className="featured-content">
                      {/* featured-content */}
                      <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                        <i className="ti ti-world" />
                      </div>
                      <div className="featured-title">
                        <h5 className="mb-0">About</h5>
                      </div>
                    </div>
                    {/* featured-content */}
                  </div>
                  {/* featured-icon-box */}
                </div>
                <div className="col-md-8">
                  <p className="mb-0 ml-30 res-767-mt-20 res-767-ml-10">
                    We specialize in small to midsize implementation and
                    deployment of any software or tools. Our consultants are
                    highly trained and specialize in quickly meeting the goals
                    of the clients. Our services are cost efficient,
                    competitive, innovative, and technically superb.
                  </p>
                </div>
              </div>
              <div className="row align-items-center ptb-50 res-991-ptb-20 iconalign-before-heading-row">
                <div className="col-md-4">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box iconalign-before-heading style1">
                    <div className="featured-content">
                      {/* featured-content */}
                      <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                        <i className="ti ti-study" />
                      </div>
                      <div className="featured-title">
                        <h5 className="mb-0">Highly Educated</h5>
                      </div>
                    </div>
                    {/* featured-content */}
                  </div>
                  {/* featured-icon-box */}
                </div>
                <div className="col-md-8">
                  <p className="mb-0 ml-30 res-767-mt-20 res-767-ml-10">
                    Our team of business analysts, project managers, coders,
                    developers, implementation team, and testing team are highly
                    educated and trained to provide any solutions to the
                    clients.
                  </p>
                </div>
              </div>
              <div className="row align-items-center ptb-50 res-991-ptb-20 iconalign-before-heading-row">
                <div className="col-md-4">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box iconalign-before-heading style1">
                    <div className="featured-content">
                      {/* featured-content */}
                      <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                        <i className="ti ti-zip" />
                      </div>
                      <div className="featured-title">
                        <h5 className="mb-0">IT Consulting & Services</h5>
                      </div>
                    </div>
                    {/* featured-content */}
                  </div>
                  {/* featured-icon-box */}
                </div>
                <div className="col-md-8">
                  <p className="mb-0 ml-30 res-767-mt-20 res-767-ml-10">
                    AR VERITY, LLC IT services consulting is headquartered in
                    Houston, Texas. We provide consulting, outsourcing services,
                    staff augmentation, support services, Cloud, cyber security
                    services, including operations in information technology
                    integration and management, enterprise solutions, custom
                    application development, user experience design, quality
                    assurance, and testing application.
                  </p>
                </div>
              </div>
              <div className="row align-items-center ptb-50 res-991-ptb-20 iconalign-before-heading-row">
                <div className="col-md-4">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box iconalign-before-heading style1">
                    <div className="featured-content">
                      {/* featured-content */}
                      <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                        <i className="ti " />
                      </div>
                      <div className="featured-title">
                        <h5 className="mb-0">24/7 Support</h5>
                      </div>
                    </div>
                    {/* featured-content */}
                  </div>
                  {/* featured-icon-box */}
                </div>
                <div className="col-md-8">
                  <p className="mb-0 ml-30 res-767-mt-20 res-767-ml-10">
                    As a global company, our offices never close and support
                    staff is available 24x7. Our support service team is known
                    for their skill sets and interest in solving any issues.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* services-section end */}
    </div>
  );
}
