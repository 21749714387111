import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { CustomCarousel } from "./styles";

export default function MainSlider() {
  const CyberSecurityVideo = require("../../assets/images/cyber-security/cyber-security-video.gif");
  const Slide2 = require("../../assets/images/41.gif");

  return (
    <CustomCarousel>
      <Carousel
        className="fullwidth-carousel"
        autoPlay={true}
        transitionTime={1}
      >
        <div
          style={{
            backgroundColor: "#fff",
            backgroundPosition: "top",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            // backgroundImage: `url(${Bg3})`,
            height: "60rem",
          }}
        >
          <img src={CyberSecurityVideo} />

          {/* <p className="legend">Focused on strategy and <span className="text-success"><b>Business Transformation</b></span></p> */}
        </div>
        <div>
          <img src={Slide2} />
           {/* <p className="legend animate__animated animate__fadeInDown animate__infinite animate__slower" style={{fontSize: 30}}>Placements and training for <span className="text-danger"><b>graduates</b></span></p> */}
        </div>
      </Carousel>
    </CustomCarousel>
  );
}
