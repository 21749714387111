import React from "react";
import { Link } from "react-router-dom";

export default function CloudOps() {
  const Img32 = require("../../../assets/images/32.png");
  const Img33 = require("../../../assets/images/33.png");

  return (
    <div>
      <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img2 row-title3-section clearfix banner-wrapper">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-1" />
            <div className="col-md-8 col-sm-10">
              <div className="text-center">
                <div className="row-title">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>CloudOps</h5>
                      <h2 className="title">
                        ARVERITY CloudOps consulting services including?
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-1" />
          </div>
        </div>
      </section>

      {/* faq-section */}
      <section className="ttm-row faq-section clearfix">
        <div className="container">
          <div className="row mt-0">
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-idea" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Strategy and Assessment</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      We offer cloud-based solutions that make cloud migration
                      and assessment simple for our clients. The team also
                      provides proven methodology and a road map to help our
                      clients overcome problems and greatly value their company
                      growth by offering a low total cost of ownership and a
                      high return on investment.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultation" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Infrastructure management</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Our professionals are available around the clock to
                      provide appropriate and client-specific CloudOps
                      assistance. Our qualified engineers and developers, as
                      well as modern cloud technologies, enable constant
                      monitoring to assure consistent performance. This
                      increases agility and operational efficiency, allowing our
                      clients to focus on innovation and development.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Automation Services</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      A precise balance between operations and development is
                      critical in today's digital world. The two's combination
                      produces a perfect blueprint for businesses to attain
                      their objectives. Creating a flexible and agile
                      environment with DevOps solutions for organizations
                      seeking efficient procedures, high-performance software,
                      and a shorter time to market.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>On-demand scalability</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Seasonal or growing demands can be fulfilled with quick
                      capacity increases, which are often provided via a
                      self-service portal. Provisioning additional virtual
                      machines, physical servers, storage, and even network
                      bandwidth is as simple as pressing a button, allowing
                      enterprises to be more nimble and meet demands as they
                      arise.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
          </div>
        </div>
      </section>
      {/* faq-section end */}

      {/* broken-section */}
      <section className="ttm-row broken-section ttm-bgcolor-grey clearfix pt-40">
        <div className="container">
          <div className="row no-gutters">
            {/* ttm_single_image-wrapper */}
            <div className="ttm_single_image-wrapper with-top-border mt_12">
              <img
                className="img-fluid"
                src={Img33}
                title="single-img-one"
                alt="single-img-one"
              />
            </div>
            {/* ttm_single_image-wrapper end */}
          </div>
          <div className="row no-gutters">
            <div className="col-sm-12">
              {/*  */}
              <div className="ttm-bg ttm-col-bgcolor-yes  spacing-1 z-1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                <div className="layer-content">
                  <h3 className="mb-30">Four pillars of cloud operations</h3>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>

                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> Abstraction</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Decouples administration from the underlying
                          infrastructure, allowing cloud machine instances,
                          storage instances, security, network, and governance
                          to be controlled from a single interface. This
                          centralized administration technique enables an
                          administrator to manage cloud-based apps and services
                          using a single tool, such as Apigee.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> Provisioning </h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Typically, an organization will handle the
                          provisioning of cloud machine instances in one of two
                          ways: self-provisioning or automated provisioning.
                          Administrators utilize self-provisioning to allow
                          cloud users to assign their own computers and track
                          their usage. Automated provisioning is more efficient
                          because it allows apps to request more resources when
                          needed and automatically deprovision them when not.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> policy orientation</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          This necessitates the development and implementation
                          of regulations that limit what users and programmes
                          may do in the public cloud. This is done to prevent an
                          application from consuming cloud resources when there
                          is no return on investment (ROI).
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> Automation</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Provisioning, user administration, security
                          management, and API management are all examples of
                          automated procedures. Artificial intelligence and
                          machine learning are critical in task automation.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* broken-section */}
    </div>
  );
}
