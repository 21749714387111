import React from "react";
import { Link } from "react-router-dom";

export default function DevOps() {
  const Img32 = require("../../../assets/images/32.png");

  return (
    <div>
      <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img2 row-title3-section clearfix banner-wrapper">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-1" />
            <div className="col-md-8 col-sm-10">
              <div className="text-center">
                <div className="row-title">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>INTEGRATIONS</h5>
                      <h2 className="title">
                        clean code in your continuous integration and continuous
                        deployment workflow
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-1" />
          </div>
        </div>
      </section>

      {/* faq-section */}
      <section className="ttm-row faq-section clearfix">
        <div className="container">
          <h3 className="title mb-30">
            ARVERITY DevOps consulting services including?
          </h3>
          <div className="row mt-0">
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-idea" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>Continuous Integration and Delivery</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Our DevOps engineers provide continuous integration and
                      delivery services for better software deployment and
                      faster time-to-market. The contemporary CI/CD pipeline
                      guarantees cheaper build and testing expenses without
                      sacrificing quality.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultation" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>DevOps Automation</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Implementing automated provisioning and building up
                      standardized software development environments with
                      automated deployments are two of our DevOps automation
                      services. Our DevOps firm designs and implements software
                      build automation solutions for Java, Node.js,.NET, mobile
                      apps for iOS/Android, and other platforms.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>DevOps Consulting</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      We advise on planning and strategy, environment setup and
                      execution, IT infrastructure testing and optimization,
                      project recovery, and DevSecOps as a DevOps consulting
                      firm. Our DevOps knowledge enables us to discover the best
                      solutions for every software development difficulty.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
            <div className="col-lg-6">
              {/* featured-icon-box */}
              <div className="featured-icon-box style7 left-icon icon-align-top mb-20">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <i className="flaticon flaticon-consultant-1" />
                  </div>
                </div>
                <div className="featured-content pl-20">
                  <div className="featured-title">
                    <h5>IT Infrastructure Implementation and Migration</h5>
                  </div>
                  <div className="featured-desc">
                    <p>
                      We give comprehensive assistance in developing the most
                      effective on-premise and cloud-based infrastructures via
                      DevOps workflows, from planning to ongoing administration
                      and maintenance. Our extensive expertise integrating SaaS,
                      IaaS, and PaaS cloud solutions and technologies can assist
                      you in easily transitioning to a contemporary IT
                      architecture.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-icon-box end */}
            </div>
          </div>
        </div>
      </section>
      {/* faq-section end */}

      {/* broken-section */}
      <section className="ttm-row broken-section ttm-bgcolor-grey clearfix pt-40">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-12">
              <div className="position-relative skin-border text-left">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper with-top-border mt_12">
                  <img
                    className="img-fluid"
                    src={Img32}
                    title="single-img-one"
                    alt="single-img-one"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
                {/*ttm-fid*/}
                <div className="ttm-fid inside ttm-fid-view-lefticon ttm-highlight-fid-style1">
                  <div className="ttm-fid-left">
                    <div className="ttm-fid-icon-wrapper">
                      <i className="ti ti-cup" />
                    </div>
                  </div>
                  <div className="ttm-fid-contents text-left">
                    <h4 className="ttm-fid-inner">
                      <span
                        data-appear-animation="animateDigits"
                        data-from={0}
                        data-to={300}
                        data-interval={20}
                        data-before
                        data-before-style="sup"
                        data-after="+"
                        data-after-style="sub"
                      >
                        300
                      </span>
                      <span className="ml-5">+</span>
                    </h4>
                    <h3 className="ttm-fid-title">Successful Customers</h3>
                  </div>
                </div>
                {/* ttm-fid end*/}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              {/*  */}
              <div className="ttm-bg ttm-col-bgcolor-yes  spacing-1 z-1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                <div className="layer-content">
                  <h3 className="mb-30">DevOps services</h3>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>

                    <div className="featured-content">
                      <div className="featured-title">
                        <h5> Professional DevOps engineers</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We provide you quick access to expert DevOps engineers
                          that will boost your project with optimal DevOps
                          techniques and cutting-edge tools.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Cost-saving</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We assist you in modernizing your IT infrastructure
                          and resources so that you can get the most out of your
                          investment.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Software lifecycle predictability</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We obtain a predictable development outcome and total
                          reliability of a software product by integrating the
                          fundamental aspects of the DevOps technique.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Agile methodology</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Our adaptable processes and methodologies enable you
                          to tailor the DevOps team to your specific needs or
                          modifications.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Complex delivery experience</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Our DevOps procedures accelerate the release cycles of
                          all types of applications, ranging from legacy to
                          agile and beyond.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}

                  {/* featured-icon-box end */}
                  <div className="separator">
                    <div className="sep-line dashed mb-25" />
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style4 left-icon icon-align-top">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                        <i className="ti ti-info ttm-num" />
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Ongoing monitoring</h5>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Our DevOps engineers regularly monitor your systems to
                          uncover flaws and resolve any issues that may arise.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* broken-section */}
    </div>
  );
}
