import styled, { css } from "styled-components";
import { cardRadius } from "../../variables";

interface Selected {
  isOpen?: boolean;
}

export const CustomCarousel = styled.div`
  .carousel-slider {
    .control-dots {
      display: none;
    }
  }

  .legend {
    background: transparent!important;
    font-size: 46px!important;
  }

  .carousel .slide img {
    height: 550px;
    object-fit: cover;

    @media (max-width: 480px) {
      height: auto;
      object-fit: contain;
    }
  }

  .control-arrow {
    background-color: blue !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 2em !important;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.2 !important;
    }
  }

  .thumbs-wrapper {
    display: flex;
    justify-content: center;
    margin: 5px;
  }

  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 3px solid #e15950;
  }

  .carousel .thumb img {
    height: 3em;
  }
`;


